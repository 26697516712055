body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url("/public/background.png") no-repeat;
}

.website {
  font-family: 'Montserrat', sans-serif;
  color: #571614;
}
.website a {
  color: #571614;
}
.website h1, .website h2, .website h3, .website h4, .website h5, .website h6 {
  font-family: "Abril Fatface", cursive;
  color: #942F2B;
}
#register-button {
  background-color: blue;
  color: white;
  text-decoration: none;
}
.web-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex {
  display: flex;
}

.flex-align-center {
  align-items: center;
}

.list-group-item {
  background: none;
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-after: always;
  }
}
.page-footer {
  height: 30px;

  position: fixed;
  bottom: 0;
  width: 100%;
}

@media print {
  .page-footer {
    page-break-after: always;
  }
}
